import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import VideosList from "../components/videos"

export default function Videos() {
  return (
    <>
      <Header />
      <VideosList />
      <Footer />
    </>
  )
}
