import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ArrowLeft from "../assets/icons/arrow-left.svg"

const Videos = () => {
  const { videos } = useStaticQuery(graphql`
    {
      videos: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(videos)/.*.md$/" } }
      ) {
        edges {
          node {
            frontmatter {
              url
              date
            }
          }
        }
      }
    }
  `)

  const videosArray = videos.edges
  videosArray.sort((a, b) =>
    a.node.frontmatter.date < b.node.frontmatter.date ? 1 : -1
  )

  return (
    <div className="mx-10 mt-20 mb-16">
      <a className="items-center section-heading font-fontspring" href="/">
        <ArrowLeft className="mr-4" /> Back
      </a>
      <div className="flex flex-wrap justify-center mt-12">
        {videosArray.map(video => (
          <iframe
            src={video.node.frontmatter.url}
            className="md:w-[500px] w-full h-[280px] md:mr-10 mb-8 md:mb-20"
          />
        ))}
      </div>
    </div>
  )
}

export default Videos
